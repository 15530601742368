import { type FC, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import type { IFooter, IPageConfig, IPageConfigFields } from 'contentful-shared'
import { BreadcrumbContext } from 'bl-common/src/context/BreadcrumbContext'
import { Footer } from 'bl-common/src/units/Footer/Footer'

import { Navigation } from './Navigation'
import { ScrollRestore } from './ScrollRestore'
import { SiteConfig } from './SiteConfig'

interface PageBaseProps {
  children: React.ReactNode
  config?: IPageConfig
  breadcrumb?: string
  scrollHeader?: boolean
  isFramed?: boolean
  footer?: IFooter
  hideFooter?: boolean
  hasSubnavigation?: boolean
  configOverride?: Partial<IPageConfigFields>
}

const Main = styled.main<{ $withNavPadding: boolean }>`
  ${({ theme, $withNavPadding }) =>
    $withNavPadding &&
    css`
      padding-top: ${theme.spacing[6]};
    `}
`

const Page: FC<PageBaseProps> = ({
  config,
  configOverride = {},
  breadcrumb,
  scrollHeader,
  footer,
  hideFooter,
  hasSubnavigation,
  children,
}) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const { saveBreadcrumb } = useContext(BreadcrumbContext)
  const pageConfig = { ...config?.fields, ...configOverride }
  const {
    hasFooter = !hideFooter,
    hasAbsolutelyPositionedNavigation,
    hasWhiteNavigation,
    hideNavigation,
    isDarkMode,
  } = pageConfig

  // Navigation position options has changed to be either 'absolute' or 'fixed'. Previously, it could also be 'static'.
  // We still need to keep the 'hasAbsolutelyPositionedNavigation' setting in contentful to be able to control
  // if pages should be under the navigation or with padding top offset from the navigation.
  const withNavPadding = !hasAbsolutelyPositionedNavigation && !hideNavigation

  const defaultScroll = () => {
    if (firstLoad) {
      setFirstLoad(false)
      return null
    }

    if (!scrollHeader) {
      return null
    }

    /*
    Commenting this code out because it seems like this isn't doing anything
    and it's causing console errors. Don't want to delete completely yet,
    just in case it turns out that there is a use case where this is needed.
    Also, when we move to NextJS 13, we want to use the built in scroll restoration
    instead as it performs better, so we won't need any of this then
    https://app.asana.com/0/1205240384235169/1206183637444920
    */

    // if (!nav) {
    //   return null
    // }

    // const { offsetHeight, offsetTop, offsetWidth } = nav

    // const isMobile = offsetWidth <= breakpoints.md - 1
    // const scrollRestoreAmount =
    //   isFramed && !isMobile
    //     ? offsetTop + offsetHeight - topicLineSize
    //     : offsetTop + offsetHeight

    // return [0, scrollRestoreAmount]
    return [0, 0]
  }

  useEffect(() => {
    if (breadcrumb && saveBreadcrumb) {
      saveBreadcrumb(breadcrumb)
    }
  }, [])

  return (
    <>
      <Navigation
        isTransparent={!!hasAbsolutelyPositionedNavigation}
        isWhiteColored={!!hasWhiteNavigation || isDarkMode}
        hasSubnavigation={hasSubnavigation}
        hideNavigation={hideNavigation}
      />
      <ScrollRestore defaultScroll={defaultScroll} />
      <Main $withNavPadding={withNavPadding}>{children}</Main>
      {hasFooter && (
        <SiteConfig>
          {({ footer: configFooter }) => (
            <Footer footer={footer || configFooter} />
          )}
        </SiteConfig>
      )}
    </>
  )
}

export default Page
