import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { breakpoints } from '../../constants/breakpoints'
import { zIndex } from '../../constants/zIndex'
import { media, mediaMax, mediaObj } from '../../utils/media'

type ItemWrapProps = {
  zIndex?: number
}

type ItemProps = {
  blurItem?: boolean
}

export const Container = styled(motion.div)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${zIndex.newLayer};
  background: var(--color-background);
  padding: 10px;

  ${media.md(css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  `)}
`

export const ItemWrap = styled(motion.div)<ItemWrapProps>`
  position: absolute;
  left: 0;
  display: flex;

  ${({ zIndex }) => css`
    z-index: ${zIndex};
  `};

  top: 50px;
  bottom: 120px;
  right: 0;
  overflow: hidden;

  height: 100%;
  width: 100%;

  @media (max-width: 850px) and (max-height: 450px) and (orientation: landscape) {
    bottom: 16px;
  }
`

export const ItemOuter = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  max-height: calc(100vh - 180px);
  width: 100%;

  ${mediaMax.md(css`
    margin: 16px;
  `)}

  @media (max-width: 850px) and (max-height: 450px) and (orientation: landscape) {
    max-height: calc(100vh - 16px);
  }
`

export const Item = styled.img<ItemProps>`
  ${({ blurItem }) =>
    blurItem &&
    css`
      filter: blur(5px);
    `};
  pointer-events: none;
  object-fit: contain;
  object-position: center 30%;
  width: auto;
  height: 100%;
`

export const ItemCounter = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: ${zIndex.newLayer * 5};

  ${media.md(css`
    right: ${({ theme }) => theme.spacing[5]};
    bottom: ${({ theme }) => theme.spacing[3]};
    top: auto;
  `)}
`

const NavButton = styled.button`
  height: 56px;
  width: 56px;
  z-index: ${zIndex.newLayer * 10};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: ${({ theme }) => theme.spacing[3]};
  height: ${({ theme }) => theme.spacing[3]};
  top: 0;
  bottom: 0;
  cursor: pointer;
`

export const NextButton = styled(NavButton)`
  display: none;
  right: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' fill='none'%3E%3Cpath stroke='%2350555B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1 1l6 6-6 6'/%3E%3C/svg%3E");

  ${media.md(css`
    display: block;
    right: ${({ theme }) => theme.spacing[1]};
  `)}

  @media (max-width: ${breakpoints.md}px) and (orientation: landscape) {
    right: 8px;
  }
`

export const PrevButton = styled(NavButton)`
  display: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' fill='none'%3E%3Cpath stroke='%2350555B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 13L1 7l6-6'/%3E%3C/svg%3E");
  left: 16px;

  ${media.md(css`
    display: block;
    left: ${({ theme }) => theme.spacing[1]};
  `)}

  @media (max-width: ${breakpoints.md}px) and (orientation: landscape) {
    left: 8px;
  }
`

export const Pagination = styled.div({
  position: 'absolute',
  bottom: 100,
  zIndex: zIndex.newLayer * 10,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',

  [mediaObj.md]: {
    display: 'none',
  },
})
