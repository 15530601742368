import type { ChangeEventHandler, FC, HTMLProps, ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from '../../styles/theme'

interface CheckboxProps {
  id: HTMLProps<HTMLInputElement>['id']
  name: HTMLProps<HTMLInputElement>['name']
  children?: ReactNode
  checked?: HTMLProps<HTMLInputElement>['checked']
  onChange?: ChangeEventHandler<HTMLInputElement>
  error?: boolean
  fillWidth?: boolean
  noAlignItems?: boolean
  labelVerticallyCentered?: boolean
  color?: string
  required?: boolean
}

const CheckBoxWrapper = styled.div<{
  fillWidth: boolean
  noAlignItems: boolean
}>`
  align-items: ${props => (props.noAlignItems ? 'unset' : 'center')};
  display: flex;
  position: relative;
  width: ${props => (props.fillWidth ? '100%' : 'unset')};
`

const Box = styled.div<{ error?: boolean; labelVerticallyCentered?: boolean }>`
  position: relative;
  margin-right: ${theme.spacing[1]};
  height: ${props => (props.labelVerticallyCentered ? '24px' : 'unset')};

  &::before {
    background: ${props =>
      props.error
        ? ' color-mix(in srgb, var(--color-error) 15%, transparent)'
        : 'var(--color-inputBackground, var(--color-fillPrimary))'};
    border: 2px solid
      ${props => (props.error ? 'var(--color-error)' : 'var(--border-color)')};
    content: '';
    height: 24px;
    width: 24px;
    display: inline-block;
    transition:
      box-shadow 150ms,
      border 150ms;
  }

  &::after {
    border-bottom: 2px solid white;
    content: none;
    border-left: 2px solid white;
    display: block;
    height: 7px;
    left: 7px;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 10px;
  }
`

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;

  &:checked + label ${Box}::after {
    content: '';
  }

  &:hover + label ${Box}::before {
    border-color: var(--color-accent);
    box-shadow: 0 0 12px
      color-mix(in srgb, var(--color-focus) 40%, transparent);
  }

  &:checked + label ${Box}::before {
    background: var(--color-accent);
    border-color: var(--color-accent);
  }

  &:focus + label ${Box}::before {
    border-color: var(--color-accent);
    box-shadow: 0 0 12px
      color-mix(in srgb, var(--color-focus) 40%, transparent);
  }
`

const Label = styled.label<{ centerVertically: boolean }>`
  position: relative;
  display: flex;
  align-items: ${props => (props.centerVertically ? 'center' : 'flex-start')};
  cursor: pointer;
  height: ${props => (props.centerVertically ? '24px' : 'unset')};
  text-align: left;
`

export const Checkbox: FC<CheckboxProps> = ({
  id,
  name,
  checked,
  onChange,
  error,
  children,
  fillWidth = true,
  noAlignItems = false,
  labelVerticallyCentered = false,
  required,
}) => {
  return (
    <CheckBoxWrapper fillWidth={fillWidth} noAlignItems={noAlignItems}>
      <Input
        id={id}
        name={name}
        checked={checked}
        type="checkbox"
        aria-invalid={error}
        onChange={onChange}
        required={required}
      />
      <Label htmlFor={id} centerVertically={labelVerticallyCentered}>
        <Box error={error} labelVerticallyCentered={labelVerticallyCentered} />
        <div>{children}</div>
      </Label>
    </CheckBoxWrapper>
  )
}
